const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api';
} else {
    baseUrl = '/api';
}

export default {
    auth: {
        login: baseUrl + '/auth/login/',
        updatePassword: baseUrl + '/auth/set-password/',
        logout: baseUrl + '/auth/logout/',
        status: baseUrl + '/auth/status/'
    },
    vueSelect: {
        speciesOptions: baseUrl + '/master-data/select/species/',
        breedOptions: baseUrl + '/master-data/select/breed/'
    },
    admin: {
        bulls: {
            list: baseUrl + '/bull/list/',
            download: baseUrl + '/download/pdf/',
            details: baseUrl + '/bull/details/'
        },
        dashboard: baseUrl + '/dashboard/count/',
        contact: {
            sendEmail: baseUrl + '/contactmail/contact/',
            list: baseUrl + '/communicate/list/'
        },
        facility: {
            list: baseUrl + '/facility/list/'
        },
        awards: {
            list: baseUrl + '/awards/list/'
        },
        banner: {
            list: baseUrl + '/banner/list/'
        },
        policy: {
            list: baseUrl + '/policy/list/'
        }
    }
};
