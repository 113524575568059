<template>
    <div class="fl-y-c py-5">
        <div class="lds-ring mb-4">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="text-center">
            <h4>Please wait while data is being loaded...</h4>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataLoadingAnimation'
};
</script>

<style scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #54b970;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #54b970 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>
