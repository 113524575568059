import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */ '../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home');
//  const About = () => import(/* webpackChunkName: "home" */'../views/About');
// const OurBulls = () => import(/* webpackChunkName: "home" */'../views/OurBulls');
// const Downloads = () => import(/* webpackChunkName: "home" */ '../views/Downloads');
const Contact = () => import(/* webpackChunkName: "home" */ '../views/Contact');
// const ContactAlter = () => import(/* webpackChunkName: "home" */ '../views/components/ContactAlter');
const BullSearch = () => import(/* webpackChunkName: "home" */ '../views/BullSearch');
const BullInfo = () => import(/* webpackChunkName: "home" */ '../views/BullInfo');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/our-bulls/:search/',
            name: 'OurBulls',
            component: BullSearch
        },
        {
            path: '/our-bulls/',
            name: 'OurBulls',
            component: BullSearch
        },
        {
            path: '/bull/:id/info/',
            name: 'BullInfo',
            component: BullInfo
        },
        {
            path: '/contact/',
            name: 'Contact',
            component: Contact
        }
    ]
};
